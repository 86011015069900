.App {
  font-family: sans-serif;
  text-align: center;
  overflow-x: hidden;
}
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
